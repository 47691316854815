document.addEventListener("DOMContentLoaded", function(event) {
	event.preventDefault();
	let currency = document.getElementsByName('currency')[0].value;

	if (!currency) return;

	if (currency == "INR") {
		window.location = "/partners/inr-buy";
	} else {
		window.location = "/partners/usd-buy";
	}
});